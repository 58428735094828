import { useEffect, useState } from 'react';
import PlanSelection from '../Components/PlanSelection';
import Navbar from '../Components/Navbar';
import Modal from '../Components/Modal';
import PaymentModal from '../Components/PaymentModal';

import { useLocation } from 'react-router-dom';
import { logData } from '../functions/logging';
export default function HomeMainPage() {
  const [name, setName] = useState(''); 
  const [mobileNumber, setMobileNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [userIsSubscribed, setUserIsSubscribed] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [view, setView] = useState('selectPlan');
  const [showModal, setShowModal] = useState(false);
  const [apiResponse, setApiResponse] = useState({});
  const [isActivePlan, setIsActivePlan] = useState(false);
  const [subscriberDetails, setSubscriberDetails] = useState({});
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [selectedPlanDetails, setSelectedPlanDetails] = useState({});

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const phoneNumber = queryParams.get('phoneNumber');
  console.log(phoneNumber);

  const plans = {
    "ott_monthly_wiom_mini": { name: "व्योम मिनी ",EnglisName: "Wiom Mini Monthly Pack", price: "₹ 5" ,code:"ott_monthly_wiom_mini" ,originalPrice:"₹ 149" ,gradientFrom: "#E4CCFF",gradientTo: "#E4CCFF",subHeading:"8 OTT का छोटा पैक, बड़ा एंटरटेनमेंट",ott:'8'},

    "ott_monthly_wiom_nasha": { name: "व्योम नशा ",EnglisName: "Wiom Nasha Monthly Pack", price: "₹ 20" ,code :"ott_monthly_wiom_nasha",originalPrice:"₹ 249" ,gradientFrom: "#E4CCFF",gradientTo: "#E4CCFF",subHeading:"11 OTT द्वारा धुआंधार मज़ा",ott:'11'},
    "ott_monthly_wiom_jhakas": { name: "व्योम झक्कास ",EnglisName: "Wiom Jhakas Monthly Pack", price: "₹ 99",code : "ott_monthly_wiom_jhakas",originalPrice:"₹ 299" ,gradientFrom: "#E4CCFF",gradientTo: "#E4CCFF" ,subHeading: "29 OTT द्वारा अनलिमिटेड एंटरटेनमेंट",ott:'29'},

  };


  const plansImages = [
    ["mini/alt.jpg","mini/DistroTV.jpg","mini/itapblack.jpg","mini/omtv.jpg","mini/powerkids.jpg","mini/primeflix.jpg","mini/sansakar.jpg","mini/shemarooMe.jpg"], // Images for Wiom Jhakas Monthly Pack
    ["nahsa/alt.jpg","nahsa/DistroTV.jpg","nahsa/fancode.jpg","nahsa/microsoftteams.jpg","nahsa/powerkids.jpg","nahsa/omtv1.jpg","nahsa/sansakar.jpg","nahsa/ptcplay.jpg","nahsa/runntv.jpg",'nahsa/sonyliv.jpg',"nahsa/ullu.jpg"], // Images for Wiom Jhakas Monthly Pack
    [ "jhakaas/aaonxticon-958.jpg",
      "jhakaas/altticon2-607.jpg",
      "jhakaas/bhaktiflix1x1-1710136999.jpg",
      "jhakaas/Chaupal_Punjabi_provider_poster_1_141.jpg",
      "jhakaas/cinemaworld1x1-1701943373.jpg",
      "jhakaas/dangal-play1x1-1703773323.jpg",
      "jhakaas/DistroTV-logo.jpg",
      "jhakaas/docubay.jpg",
      "jhakaas/dol-logo-165.jpg",
      "jhakaas/fan-61.jpg",
      "jhakaas/icon-833-1689834498.jpg",
      "jhakaas/istream-832.jpg",
      "jhakaas/itapblack1x1-297.jpg",
      // "jhakaas/jhakaas.jpg",
      "jhakaas/kanccha-lannka-icon-210.jpg",
      "jhakaas/microsoftteams-image-1706532855.jpg",
      "jhakaas/omtv1x1-1703674855.jpg",
      "jhakaas/PlayFlix_provider_poster_1_55.jpg",
      "jhakaas/powerkids1x1-1-1709209457.jpg",
      "jhakaas/primeflix-11-1709729465.jpg",
      "jhakaas/ptc-play.jpg",
      "jhakaas/raj-609.jpg",
      "jhakaas/runntv1x1-1702897676.jpg",
      "jhakaas/sansakar.jpg",
      "jhakaas/shemarooMe.jpg",
      "jhakaas/sonyliv.jpg",
      "jhakaas/stageicon-154.jpg",
      "jhakaas/ullu1x1-1702988281.jpg",
      "jhakaas/vrott1x1-1702988328.jpg",
      "jhakaas/zee5.jpg"], // Images for Wiom Jhakas Monthly Pack
  ];
  const plansImagesS = [
    ["mini/alt.jpg"], // Images for Wiom Jhakas Monthly Pack
    ["nahsa/alt.jpg",'nahsa/sonyliv.jpg',"nahsa/ullu.jpg"], // Images for Wiom Jhakas Monthly Pack
      ["jhakaas/altticon2-607.jpg",
      "jhakaas/sonyliv.jpg",
      "jhakaas/ullu1x1-1702988281.jpg",
      "jhakaas/zee5.jpg"], // Images for Wiom Jhakas Monthly Pack
  ];




  const handlePlanSelection = (planCode) => {
    console.log('Selected plan:', planCode);
    setSelectedPlan(planCode);
    setShowModal(true); // Show modal after plan selection
  };

  const handleNameChange = (newName) => {
    setName(newName);
  };

  const handlePhoneNumberSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const url = 'http://labs-api.wiom.in/get_status_ott';
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ phone: mobileNumber })
      });

      const data = await response.json();
      setLoading(false);

      if (response.ok && data.data.success) {
        if (data.data.subscriber_details?.active_plan) {
          setSubscriberDetails(data.data.subscriber_details);
          setIsActivePlan(true);
          setUserIsSubscribed(true);
          setResponseMessage("Plan is active, enjoy the latest content on OTTPlay app.");

          // Close the modal after 5 seconds
          setTimeout(() => {
            setShowModal(false);
            window.location.reload(); // This will refresh the page

          }, 5000);
        } else {
          setUserIsSubscribed(false);
          setIsActivePlan(false);
          console.log('User is trying to subscribe to:', selectedPlanDetails);
        }
      } else {
        setUserIsSubscribed(false);
        setIsActivePlan(false);
        setResponseMessage(data.data.msg || "Please complete your registration.");
        setView('selectPlan');
      }
    } catch (error) {
      console.error('Error checking subscription status:', error);
      setLoading(false);
      setResponseMessage('Failed to check subscription status. Please try again.');
      setView('selectPlan');
    }
  };

  const handlePhoneNumberChange = (event) => {
    setMobileNumber(event.target.value);
  };

  const handleSignOut = () => {
    setMobileNumber('');
    setUserIsSubscribed(false);
    setSelectedPlan('');
    setSubscriberDetails({});
    setApiResponse({});
    setIsActivePlan(false);
    setShowModal(false);
    setView('selectPlan');
    setName('');
  };

  const closeModal = () => {
    setShowModal(false);
    window.location.reload(); // This will refresh the page

  };

  useEffect(() => {
    // Prepare the data you want to log
    const logPayload = {
      topic: 'pmwani_logs',
      name: 'OTTPLAY_PLAN_PAGE_LOADED',
      key: 'ott',
      mobile: phoneNumber, // Assuming phoneNumber is available
      feature: 'OTTPLAY_PLAN_PAGE_LOADED',
      data: {
         transactionId: 'N/A',
        mobile: phoneNumber || 'N/A',
        planCode:  'N/A',
        status: 'Page Loaded',
        username:  'N/A',
      },
    };
  
    // Call the logData function to log the data
    logData(logPayload);
  
  }, [phoneNumber, selectedPlan, name]);

  return (
    
    <div className="bg-black-500 min-h-screen flex flex-col bg-[#faf9fc]">
      <div>
      <Navbar backgroundColor={'#faf9fc'} logoSrc={'/wiom-icon.svg'} routeToNavigate={'/main'} wiomVisible={true}/>
      </div>
      <div className='w-screen' >
      <div className=" text-black p-6 text-left text-2xl font-bold font-['Noto Sans']">अपना प्लान चुनें और अभी स्ट्रीमिंग शुरू करें</div>
      </div>


      <div className='px-6'>
      <PlanSelection plans={plans} plansImages={plansImages} onSelectPlan={handlePlanSelection} phoneNumber = {phoneNumber} plansImagesS={plansImagesS} />
      </div>
    </div>
  );
}
