import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../Components/Navbar";
import PrePaymentModal from "../Components/PrePaymentModal";
import { logData } from "../functions/logging";
export default function PrePayment() {
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedPlan } = location.state || {};
  console.log(selectedPlan.phoneNumber);
  const initialPhone = selectedPlan?.phoneNumber || "";

  const [name, setName] = useState("WiomUser");
  const [phone, setPhone] = useState(initialPhone=="null"?"":initialPhone  );
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showFullscreenPaymentModal, setShowFullscreenPaymentModal] =
    useState(false);

  const [paymentStarted, setPaymentStarted] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [polling, setPolling] = useState(false);

  const [currentActivePlan,setCurrentActivePlan] = useState('');
  const isNameValid = /^[A-Za-z\s]+$/.test(name);
  const isPhoneValid = /^\d{10}$/.test(phone);

  // Handle viewport resize for mobile keyboards
  useEffect(() => {
    console.log(selectedPlan)
    const handleResize = () => {
      setIsButtonVisible(window.innerHeight > 600); // Adjust this value as necessary
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Function to handle API call on button click
  const handlePhoneNumberSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    logData({
      topic: 'pmwani_logs',
      name: 'PAYMENT_CTA_CLICKED',
      key: 'ott',
      mobile: phone,
      feature: 'PrePaymentOTT',
      data: {
        transactionId: 'N/A',
        mobile: phone,
        planCode: selectedPlan.planDescription.code,
        status: 'CTA Clicked',
        username: name,
      },
    });

    const url = "https://labs-api.wiom.in/get_status_ott";
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ phone }),
      });

      const data = await response.json();
      // setLoading(false);

      console.log("API Response:", data);
      setCurrentActivePlan(data.subscriber_details?.plan_details.name);

      if (data.status_code === 200) {
        if (data.data.subscriber_details?.active_plan) {
          setShowModal(true); // Show the modal if the user has an active plan
          

        } else if (
          data.data.msg ===
          "Subscriber is not Created. Please create new subscriber."
        ) {
            handlePayment({
                returnUrl: `${window.location.origin}/success`,
                extraData: {
                  name: name,
                  phoneNumber: phone,
                  planCode: selectedPlan.planDescription.code,
                },
              });
               // Initiate payment if the user needs to create a new subscriber
        } else {
          console.log("Unexpected response:", data.data.msg);
          handlePayment({
                returnUrl: `${window.location.origin}/success`,
                extraData: {
                  name: name,
                  phoneNumber: phone,
                  planCode: selectedPlan.planDescription.code,
                  isRenewal: true,
                },
              });
          // setShowModal(true); // Show the modal if the user has an active plan

        }
      } else {
        setResponseMessage(
          data.data.msg || "Please complete your registration."
        );
      }
    } catch (error) {
      console.error("Error checking subscription status:", error);
      setLoading(false);
      setResponseMessage(
        "Failed to check subscription status. Please try again."
      );
    }
  };

  const handlePayment = async ({ returnUrl, extraData }) => {
    setPaymentStarted(true);
    try {
      const amount = selectedPlan.planDescription.price.replace("₹ ", "");
      
      // Construct the URL with query parameters
      const url = `https://payment.stage.i2e1.in/api/AddOnPayment/CreateTransactionForCxTeam?mobile=${encodeURIComponent(phone)}&amount=${encodeURIComponent(amount)}&returnUrl=${encodeURIComponent(returnUrl)}&name=${encodeURIComponent(extraData.name)}&phoneNumber=${encodeURIComponent(extraData.phoneNumber)}&planCode=${encodeURIComponent(extraData.planCode)}`;
  
      const response = await fetch(url);
      const data = await response.json();
  
      if (data.status === 0 && data.data.jusPayResponse) {
        setTransactionId(data.data.transaction_id); // Set the transaction ID for polling
        setPolling(true); // Start polling
        setShowFullscreenPaymentModal(true); // Open payment modal
  

        logData({
          topic: 'pmwani_logs',
          name: 'OTTPLAY_TRANSACTION_INTIATED',
          key: 'ott',
          mobile: phone,
          feature: 'PrePaymentOTT',
          data: {
            transactionId: data.data.transaction_id,
            mobile: phone,
            planCode: extraData.planCode,
            status: 'Transaction Link Received',
            username: extraData.name,
          },
        });


        // Open the Juspay payment URL in the current tab
        window.open(data.data.jusPayResponse.payment_links.web, '_self');
      } else {
        console.error("Error initiating payment:", data.msg);
      }
    } catch (error) {
      console.error("Error during payment request:", error);
    }
  };
  
  

  return (
    <>
      <>
        <Navbar
          backgroundColor={"#ffffff"}
          logoSrc={"arrow_back.svg"}
          routeToNavigate={"/main"}
          WiomVisible={false}
        />
        <div className="w-full h-20 px-6 py-2 justify-center text-left">
          <div className="w-full text-[#161021] text-2xl font-bold font-['Noto Sans'] leading-loose">
          स्ट्रीमिंग शुरू करने के लिए अपनी डिटेल्स भरें
          </div>
        </div>

        <div className="px-8 py-12">
          <div className="text-left py-1 font-bold font-['Noto Sans'] text-[#161021]">
            अपना नाम लिखें
          </div>
          <div className="bg-[#faf9fc] flex items-center p-2 rounded-lg border border-[#a7a1b2] focus-within:border-[#161021]">
            <div className="w-6 h-6 relative">
              <img
                className="absolute w-full h-full"
                src={"user_name.svg"}
                alt="User Icon"
              />
            </div>
            <div className="grow shrink basis-0 h-6 flex justify-start items-center gap-1 ml-2">
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="उदाहरण: Ramesh Kumar"
                className="w-full bg-transparent font-bold text-[#161021] placeholder-[#a7a1b2] placeholder:font-normal focus:outline-none w-full rounded-lg"
              />
            </div>
          </div>
          <div className="h-6"></div>
          <div className="text-left py-1 font-bold font-['Noto Sans'] text-[#161021]">
            अपना फ़ोन नंबर लिखें
          </div>
          <div className="relative flex flex-col items-start">
            <div className="bg-[#faf9fc] flex items-center p-2 rounded-lg border border-[#a7a1b2] w-full focus-within:border-[#161021]">
              <div className="w-6 h-6 relative">
                <img
                  className="absolute w-full h-full"
                  src={"phone.svg"}
                  alt="Phone Icon"
                />
              </div>
              <div className="grow shrink basis-0 h-6 flex justify-start items-center gap-1 ml-2">
                <input
                  type="text"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  onFocus={() => setIsTooltipVisible(true)}
                  onBlur={() => setIsTooltipVisible(false)}
                  placeholder="उदाहरण: 9876543210"
                  className="w-full bg-transparent font-bold text-[#161021] placeholder-[#a7a1b2] placeholder:font-normal focus:outline-none w-full rounded-lg"
                />
              </div>
            </div>
            {/* Tooltip */}
            {isTooltipVisible && (
              <Tooltip message="वह नंबर लिखें जिसपर आप OTT प्लान का मज़ा लेना चाहते हैं। " />
            )}
          </div>
        </div>

        {/* Show button only if both name and phone are valid and the button is visible */}
      {isNameValid && isPhoneValid && (
        <div
          className={`fixed bottom-0 left-0 right-0 flex justify-center ${
            loading ? "opacity-50 cursor-not-allowed" : ""
          }`}
          style={{ boxShadow: "0 4px 6px rgba(255, 255, 255, 1.0)" }}
        >
          <div className="w-full max-w-lg h-20 p-4 bg-white shadow-lg flex-col justify-start items-center gap-4 inline-flex">
            <div
              className="w-full px-4 py-3 bg-[#d9008d] rounded-2xl justify-center items-center gap-3 inline-flex"
              onClick={!loading ? handlePhoneNumberSubmit : null}
            >
              {loading ? (
                <div className="loader grow shrink basis-0 text-center text-[#faf9fc] text-base font-bold font-['Noto Sans'] leading-normal">Loading ... :/</div> // You can replace this with your own loader component or a spinner
              ) : (
                <div className="grow shrink basis-0 text-center text-[#faf9fc] text-base font-bold font-['Noto Sans'] leading-normal">
                  ₹{selectedPlan.planDescription.price.replace("₹ ", "")} देकर OTT पाएं
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      </>

      {/* Modal */}
      {showModal && (
        <PrePaymentModal
          phone={phone}
          onClose={() => navigate(`/download-app?mobile=${phone}`)} 
          planName={currentActivePlan}// Close the modal and navigate back to /main
        />
      )}
    </>
  );
}

const Tooltip = ({ message }) => (
  <div className="mt-2 px-2 py-3 bg-[#e3ccff] text-[#6d17ce] text-left text-sm font-semibold font-['Noto Sans'] leading-tight rounded shadow-lg">
    {message}
  </div>
);
